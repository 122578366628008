dmx.Attribute("html", "mounted", function (node, attr) {
  if (node.dmxHtml) return;

  node.dmxHtml = {
    component: this,
  };

  this.$watch(attr.value, html => {
    node.innerHTML = html != null ? String(html) : "";
  });
});
