dmx.Attribute("text", "mounted", function (node, attr) {
  if (node.dmxText) return;

  node.dmxText = {
    component: this,
  };

  this.$watch(attr.value, text => {
    node.innerText = text != null ? String(text) : "";
  });
});
