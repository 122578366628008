(function() {

  // TODO: ImageData compare

  var hasOwnProperty = Object.prototype.hasOwnProperty;

  var reTypedType = /^(?:Float(?:32|64)|(?:Int|Uint)(?:8|16|32)|Uint8Clamped)Array$/;

  var eq = function(value, other) {
    return value === other || (value !== value && other !== other);
  }

  var getType = function(value) {
    return toString.call(value).slice(8, -1);
  };

  var isObject = function(value) {
    return typeof value === 'object' && value !== null;
  };

  var isTypedArray = function(value) {
    return isObject(value) && reTypedType.test(getType(value));
  };

  var mapToArray = function(map) {
    var index = -1;
    var result = new Array(map.size);
    map.forEach(function(value, key) {
      result[++index] = [key, value];
    });
    return result;
  };

  var setToArray = function(set) {
    var index = -1;
    var result = new Array(set.size);
    set.forEach(function(value) {
      result[++index] = value;
    });
    return result;
  }

  var equalByType = function(object, other, type) {
    switch (type) {
      case 'DataView':
        if (object.byteLength != other.byteLength || object.byteOffset != other.byteOffset) {
          return false;
        }
        object = object.buffer;
        other = other.buffer;

      case 'ArrayBuffer':
        if (object.byteLength != other.byteLength || !equal(new Uint8Array(object), new Uint8Array(other))) {
          return false;
        }
        return true;

      case 'Boolean':
      case 'Date':
      case 'Number':
        return eq(+object, +other);

      case 'RegExp':
      case 'String':
        return object == String(other);

      case 'Map':
      case 'Set':
        if (object.size != other.size) return false;
        return equalArrays(Array.from(object), Array.from(other));
    }
  };

  var equalArrays = function(array, other) {
    var arrLength = array.length;
    var othLength = other.length;

    if (arrLength != othLength) {
      return false;
    }

    var index = -1;

    while (++index < arrLength) {
      var arrValue = array[index];
      var othValue = other[index];

      if (!(arrValue === othValue || equal(arrValue, othValue))) {
        return false;
      }
    }

    return true;
  };

  var equalObjects = function(object, other) {
    var objProps = Object.keys(object);
    var objLength = objProps.length;
    var othProps = Object.keys(other);
    var othLength = othProps.length;

    if (objLength != othLength) {
      return false;
    }

    var key, index = objLength;
    
    while (index--) {
      key = objProps[index];
      if (!hasOwnProperty.call(other, key)) {
        return false;
      }
    }

    var result = true;

    while (++index < objLength) {
      key = objProps[index];
      var objValue = object[key];
      var othValue = other[key];

      if (!(objValue === othValue || equal(objValue, othValue))) {
        result = false;
        break;
      }
    }

    if (result) {
      var objCtor = object.constructor;
      var othCtor = other.constructor;

      if (objCtor != othCtor &&
        ('constructor' in object && 'constructor' in other) &&
        !(typeof objCtor === 'function' && objCtor instanceof objCtor &&
          typeof othCtor === 'function' && othCtor instanceof othCtor)) {
        result = false;
      }
    }

    return result;
  };

  var equalDeep = function(object, other) {
    var objIsArr = Array.isArray(object);
    var othIsArr = Array.isArray(other);
    var objType = objIsArr ? 'Array' : getType(object);
    var othType = othIsArr ? 'Array' : getType(other);
    var objIsObj = objType == 'Object';
    var othIsObj = othType == 'Object';
    var isSameType = objType == othType;

    if (isSameType && !objIsObj) {
      return (objIsArr || isTypedArray(object))
        ? equalArrays(object, other)
        : equalByType(object, other, objType);
    }

    if (!isSameType) {
      return false;
    }

    return equalObjects(object, other);
  };

  var equal = function(value, other) {
    if (value === other) {
      return true;
    }
    if (value == null || other == null || (!isObject(value) && !isObject(other))) {
      return value !== value && other !== other;
    }
    return equalDeep(value, other);
  };

  dmx.equal = equal;

})();